#section-1 { height: 95vh; }
#logo-holder { position: relative; margin: 20vh 0 30vh 0; }
#peta-cover { width: 270px; }
#peta-line {
  position: absolute;
  animation: lineslide 4s linear infinite;
  top: 97px;
  left: 60px;
}
@keyframes lineslide {
  10%{ transform: rotate(0deg); top: 97px; left: 60px; } 
  20%{ transform: rotate(40deg); top: 110px; left: 220px; }
  25%{ transform: rotate(90deg); top: 130px; left: 250px; }
  30%{ transform: rotate(140deg); top: 160px; left: 230px; }
  40%{ transform: rotate(180deg); top: 160px; left: 60px; }
  60%{ transform: rotate(180deg); top: 160px; left: 60px; }
  70%{ transform: rotate(140deg); top: 160px; left: 230px; }
  75%{ transform: rotate(90deg); top: 130px; left: 250px; }
  80%{ transform: rotate(40deg); top: 110px; left: 220px; }
  90%{ transform: rotate(0deg); top: 97px; left: 60px; }
}


.display-images {
  display: block;
  max-height: 35vh;
  text-align:center;
  width: 100%;
  height: calc(100vw / 4);
  margin-bottom: 15px;

  img {
      width: auto; 
      height: 100%; 
  }
}

.display-section { 
  width: 70vw;
  padding-bottom: .5em;
  border-bottom: solid 1px rgb(213, 213, 213);
  margin: auto;
  margin-bottom: 50px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2em;
  ul { margin: 15px; }
}
.work-notes{
  padding: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2em;
  text-align: justify;
}

.to-top { text-align: right; padding-top: 40px; }




.item {
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  padding: 1em;
  img { height: 100px; display: block; margin: auto; }
}

// .widget-display {
//   h3 { margin: 0; }
// }



@media all and (max-width: 1100px){

  .display-section {
    width: 80vw;
  }
  // #section-2 { 
  //   padding: 0 10vw;
  // }

}

@media all and (min-width: 769px){
  .grid-sml {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 0.5em 0.5em;
    grid-auto-flow: row dense;
  }
}



@media all and (max-width: 768px){

  .mobile-hide {
    display: none;
  }

  .work-notes{ font-size: 1.1em; }
  .display-section {
    width: 90vw;
    font-size: 1em;
  }
  .display-images {
    height: calc(100vw / 3);
  }
  .grid-sml {
    display: block;
    margin: auto;
    width: 300px;
  }
  .item {
    margin: 5px;
  }
  // #section-2 { padding: 0 4vw; }
  

}

@media all and (max-width: 550px){
 
      section { 
          width: 90vw;
          margin-bottom: 40px;
          margin-top: 10px;
          ul { padding: 5px;}
      }
      .work-notes{font-size: 1em; }
      // .display-section { width: 95vw; }


}




 @media all and (min-width: 370px){
    .work-emblem{ margin: 5px 30px 20px 10px; float: left; }

 }

