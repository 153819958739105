
:root {
  --base-txt-color: #222;
  --test-color: rgb(7, 212, 231);

  --heading-color: --brand-dark;
  --base-font-family: 'Work Sans', sans-serif;
  --body-bg: #fff;

  --brand-light: #00D1C3;
  --brand-dark: #07A898;
  --link-color: --brand-dark;

  --header-active: #057265;
  --header-inactive: #9B9B9B;

  --info-one: #F9C82D;
  --info-two: #61CE63;
  --info-three: #6390D6;
  
  --action-color: #EF7043;
  --brand-lighter: #5CF2E4;

  --bginfo-color: #FCF3E2;
  --disabled-color: #C6C6C6; 


  //DATE PICKER colour theme:
  --date-picker1: #F96648; 
  --date-picker2: #F9D5CA; 
  --date-picker3: #FF8555; 
  --date-pickerA: #444343; 
  --date-pickerB: #9B9B9B;
  --date-pickerC: #e4e4e3; 
  // END DATE PICKER


  --test-color: rgba(255, 255, 255, 0.9);
  --test-color2: rgba(203, 203, 203, 0.6);
  --test-color3: #F9D5CA; 
  --test-color4: #e4e4e3; 


}