
.work-box{ width: 60vw; }

.banner-img {
  width: 100%;
  aspect-ratio: 4 / 1;
}

// .project-box{ margin-top: 50px; margin-bottom: 50px; }

.work-notes{
  padding: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2em;
  text-align: justify;
}

.work-logo{
  width: 65px;
  margin: 40px 10px 20px 30px;
  float: right;
}

.work-emblem{ width: 150px; margin: auto; }

.work-section { 
  position: relative; 
  padding: 0 20vw;
  margin: 5vh 3vh;
}

#pin-up {
  width: 80px;
  z-index: 50;
  position: absolute;
  top: 50px;
}

#pin-notes{
  background-color: rgb(247, 245, 221);
  padding: 3em 2em 2em 2em;
  font-size: 1.3em;
  border-radius: 10px;
  margin: 120px 10vh 20px 10vh;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.4);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transform: skewY(-4deg);
}

#note-plane{ float: right; }
#logo-second { margin: 8vh 0; width: 30vw; }
#section-b { padding: 10% 0; }


@media all and (max-width: 1100px){
  // .main-section-right { padding: 5vh 0; }
  #logo-second { width: 40vw; }
  .work-section { 
    padding: 0 10vw;
  }
}

@media all and (max-width: 768px){
  .work-box{ width: 75vw; }
  .work-notes{ font-size: 1.1em; }
  #logo-second { width: 50vw; margin: 4vh 0; }
  #pin-notes{
    padding: 3em 1.5em 1.5em 1.5em;
    font-size: 1.1em;
    margin: 120px 3vw 20px 3vw;
  }

  .work-section { 
    margin: 5vh auto;
    padding: 0 5vw;
  }
}

@media all and (max-width: 450px){
  #logo-second { width: 60vw; }
  #pin-notes{
    padding: 3em 1.3em 1.3em 1.3em;
    font-size: 1.1em;
  }
  .work-notes{ padding: 1em; font-size: 1em; }
  .work-box{ width: 90vw; }
  .work-section { 
    padding: 0;
  }
}


 @media all and (min-width: 370px){
  .work-emblem{ margin: 5px 30px 20px 10px; float: left; }
 }

